<template>
  <div class="col-12 col-sm-3 col-xl-2 px-sm-2 px-0 d-flex sticky-top sidebar vh-100 remove-vh-100-sm">
    <div
      class="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-3 pt-2 text-white">
      <span class="d-flex align-items-center pb-sm-3 mb-md-0 me-md-auto text-white text-decoration-none me-3">
        <img src="@/assets/logo-nav.png" class="img-fluid" />
      </span>
      <ul
        class="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start w-100"
        id="menu">
        <li class="nav-item w-100">
          <a class="nav-link d-flex align-items-center" @click="showHome" :class="{ selected: selectedItem === 'Home' }"
            href="#" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
            <span class="material-icons text-main-gradient ">home</span> <span
              class="d-none d-sm-inline ms-2">Home</span></a>
        </li>
        <li class="w-100">
          <a href="#" @click="showUsers" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
            class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Users' }"
            v-if="hasPermission('user', user.permissions)">
            <span class="material-icons text-main-gradient ">people</span>
            <span class="d-none d-sm-inline ms-2">Client manager</span></a>
        </li>
        <li class="w-100">
          <a href="#" @click="showMessage" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
            class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Inventory' }"
            v-if="hasPermission('user', user.permissions)">
            <span class="material-icons text-main-gradient ">inventory</span>
            <span class="d-none d-sm-inline ms-2">Inventory</span></a>
        </li>
        <li class="w-100">
          <a href="#" @click="showMessage" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
            class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Inventory' }"
            v-if="hasPermission('user', user.permissions)">
            <span class="material-icons text-main-gradient ">apartment</span>
            <span class="d-none d-sm-inline ms-2">In-Office WO</span></a>
        </li>
        <li class="w-100">
          <a href="#" @click="showMessage" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
            class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Inventory' }"
            v-if="hasPermission('user', user.permissions)">
            <span class="material-icons text-main-gradient ">settings_remote</span>
            <span class="d-none d-sm-inline ms-2">Remote Support</span></a>
        </li>
        <li class="w-100">
          <a href="#" @click="showMessage" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
            class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Inventory' }"
            v-if="hasPermission('user', user.permissions)">
            <span class="material-icons text-main-gradient ">nature</span>
            <span class="d-none d-sm-inline ms-2">Field WO</span></a>
        </li>
        <!-- <li class="w-100">
          <a href="#" @click="callTiers" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
            class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Tiers' }"
            v-if="hasPermission('buy', user.permissions)">
            <span class="material-icons text-main-gradient ">playlist_add</span>
            <span class="d-none d-sm-inline ms-2">Add Tier</span></a>
        </li>
        <li class="w-100">
          <a href="#" @click="callAddons" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
            class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Addons' }"
            v-if="hasPermission('buy', user.permissions)">
            <span class="material-icons text-main-gradient ">electrical_services</span>
            <span class="d-none d-sm-inline ms-2">Addons</span></a>
        </li> -->
        <li class="w-100">
          <a href="#" @click="showSettings" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
            class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Settings' }">
            <span class="material-icons text-main-gradient ">settings</span>
            <span class="d-none d-sm-inline ms-2">Settings</span></a>
        </li>
        <!-- <li class="dropdown">
          <a href="#" class="nav-link dropdown-toggle px-sm-0 px-1" id="dropdown" data-bs-toggle="dropdown"
            aria-expanded="false">
            <i class="fs-5 bi-bootstrap"></i><span class="ms-1 d-none d-sm-inline">Bootstrap</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdown">
            <li><a class="dropdown-item" href="#">New project...</a></li>
            <li><a class="dropdown-item" href="#">Settings</a></li>
            <li><a class="dropdown-item" href="#">Profile</a></li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li><a class="dropdown-item" href="#">Sign out</a></li>
          </ul>
        </li>
        <li>
          <a href="#" class="nav-link px-sm-0 px-2">
            <i class="fs-5 bi-grid"></i><span class="ms-1 d-none d-sm-inline">Products</span></a>
        </li>
        <li>
          <a href="#" class="nav-link px-sm-0 px-2">
            <i class="fs-5 bi-people"></i><span class="ms-1 d-none d-sm-inline">Customers</span> </a>
        </li> -->
      </ul>
      <!-- <div class="dropdown py-sm-4 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1"> -->
      <div class="dropdown py-2 mt-auto w-100 w-sm-auto ms-auto ms-sm-0 flex-shrink-1">

        <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1"
          data-bs-toggle="dropdown" aria-expanded="false">
          <img src="@/assets/avatar.webp" alt="hugenerd" width="27" height="27" class="rounded-circle">
          <span class="d-none d-sm-inline mx-1 smallText">{{ displayName }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-dark text-small " aria-labelledby="dropdownUser1">
          <!-- <li><a class="dropdown-item" href="#">New project...</a></li>
          <li><a class="dropdown-item" href="#">Settings</a></li>
          <li><a class="dropdown-item" href="#">Profile</a></li>
          <li>
            <hr class="dropdown-divider">
          </li> -->
          <li><a class="dropdown-item" href="#" @click="logoutUser()"><span
                class="material-icons smallText me-1">power_settings_new</span> Logout</a></li>

        </ul>
      </div>
    </div>
  </div>
  <!-- <nav class="col-md-2 col-lg-2 d-md-block sidebar collapse position-fixed">
    <div class="d-flex justify-content-center">
      <div class="navbar-brand">
        <img src="@/assets/logo-nav.png" width="133" />
      </div>
    </div>
    <div class="list-group">
      <hr />
      <a class="nav-link d-flex align-items-center" @click="showHome" :class="{ selected: selectedItem === 'Home' }"
        href="#" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
        <span class="material-icons text-main-gradient me-2">home</span> Home</a>
      <a href="#" @click="showUsers" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
        class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Users' }"
        v-if="hasPermission('user', user.permissions)">
        <span class="material-icons text-main-gradient me-2">people</span>
        Users</a>
      <a href="#" @click="callTiers" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
        class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Tiers' }"
        v-if="hasPermission('buy', user.permissions)">
        <span class="material-icons text-main-gradient me-2">playlist_add</span>
        Add Tier</a>
      <a href="#" @click="callAddons" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
        class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Addons' }"
        v-if="hasPermission('buy', user.permissions)">
        <span class="material-icons text-main-gradient me-2">electrical_services</span>
        Addons</a>
      <a href="#" @click="showSettings" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
        class="nav-link d-flex align-items-center" :class="{ selected: selectedItem === 'Settings' }">
        <span class="material-icons text-main-gradient me-2">settings</span>
        Settings</a>
    </div>
    <div class="position-absolute bottom-0 w-100 text-center mb-3">
      <span class="smallText"> AgtLink v1.0.0</span>
    </div>
  </nav> -->
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import { auth } from "@/fb";
  export default {
    data() {
      return {
        selectedItem: "Sidebar",
        user: [],
      };
    },
    computed: {
      ...mapState(["displayName"]),
    },
    created() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.updateDisplayName(user.displayName);
          this.$emit("displayNameChanged", user.displayName);
        } else {
          this.updateDisplayName("--");
          this.$emit("displayNameChanged", "--");
        }
      });
    },
    mounted() {
      setTimeout(() => {
        this.user = localStorage.getItem("user");
        if (this.user) {
          this.user = JSON.parse(this.user);
        }
      }, 1000);
    },
    methods: {
      ...mapActions(["updateDisplayName"]),
      logoutUser() {
        // Implementa el método de cierre de sesión
        this.logout();
      },
      callTiers() {
        console.log("click", this.$route.path);
        if (this.$route.path !== "/home") {
          this.selectedItem = "Tiers";
          this.$router.push({ name: "Home" });
          setTimeout(() => {
            this.$store.dispatch("callTiers");
          }, 1500);
          setTimeout(() => {
            this.selectedItem = "Home";
          }, 2000);
        } else {
          this.selectedItem = "Tiers";
          this.$store.dispatch("callTiers");
          setTimeout(() => {
            this.selectedItem = "Home";
          }, 1000);
        }
      },
      callAddons() {
        console.log("click", this.$route.path);
        if (this.$route.path !== "/home") {
          this.selectedItem = "Addons";
          this.$router.push({ name: "Home" });
          setTimeout(() => {
            this.$store.dispatch("callAddons");
          }, 1500);
          setTimeout(() => {
            this.selectedItem = "Home";
          }, 2000);
        } else {
          this.selectedItem = "Addons";
          this.$store.dispatch("callAddons");
          setTimeout(() => {
            this.selectedItem = "Home";
          }, 1000);
        }
      },
      showHome() {
        if (this.$route.path !== "/home") {
          this.$router.push({ name: "Home" });
          this.selectedItem = "Home";
        }
      },
      showUsers() {
        if (this.$route.path !== "/users") {
          this.$router.push({ name: "Users" });
          this.selectedItem = "Users";
        }
      },
      showFarms() {
        if (this.$route.path !== "/farms") {
          this.$router.push({ name: "Farms" });
          this.selectedItem = "Farms";
        }
      },
      showSettings() {
        if (this.$route.path !== "/settings") {
          this.$router.push({ name: "Settings" });
          this.selectedItem = "Settings";
        }
      },
      showLogs() {
        if (this.$route.path !== "/logs") {
          this.$router.push({ name: "Logs" });
          this.selectedItem = "Logs";
        }
      },
      showMessage() {
        this.notify("success", "Coming soon 🙌");
      },
      handleMouseOver(event) {
        event.target.classList.add("hovered");
      },
      handleMouseLeave(event) {
        event.target.classList.remove("hovered");
      },
    },
  };
</script>

<style scoped>
  .nav-link:hover {
    transform: scale(1.09);
    transition: transform 0.2s ease;
    background-color: #46616e67 !important;
    /* background-color: #bcf0fe67 !important; */
    color: white !important;
    border-radius: 6px 6px 6px 6px !important;
    box-shadow: 1px 1px 9px #171717;
  }

  .selected {
    /* Estilos para el elemento seleccionado (cambialos según tus necesidades) */
    transform: scale(1.09);
    transition: transform 0.2s ease;
    background-color: #214455 !important;
    /* background-color: #90ffd2 !important; */
    color: white !important;
    /* border-radius: 0px 6px 6px 0px !important;
    box-shadow: 0px 1px 0px #4ae459; */
    border-radius: 6px 6px 6px 6px !important;
    box-shadow: 0px 2px 0px #235e47;
  }

  body.light-mode .nav-link:hover {
    transform: scale(1.09);
    transition: transform 0.2s ease;
    /* background-color: #46616e67 !important; */
    background-color: #bcf0fe67 !important;
    color: white !important;
    border-radius: 6px 6px 6px 6px !important;
    box-shadow: 1px 1px 6px #b2c7c2;
  }

  body.light-mode .selected {
    /* Estilos para el elemento seleccionado (cambialos según tus necesidades) */
    transform: scale(1.09);
    transition: transform 0.2s ease;
    /* background-color: #214455 !important; */
    background-color: #90ffd2 !important;
    color: white !important;
    /* border-radius: 0px 6px 6px 0px !important;
    box-shadow: 0px 1px 0px #4ae459; */
    border-radius: 6px 6px 6px 6px !important;
    box-shadow: 1px 1px 1px #4ae459;
  }
</style>