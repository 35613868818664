<template>
  <div>
    <div class="container-fluid overflow-hidden">
      <div class="row vh-100 remove-vh-100-sm2  overflow-auto">
        <Sidebar />

        <main class="col d-flex flex-column h-sm-100">
          <Navbar />
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
  import Navbar from '@/components/Navbar.vue';
  import Sidebar from '@/components/Footer.vue';
  import axios from 'axios';
  var config = require("@/config/api.json");
  const API = config.api;
  import { CardElement } from '@stripe/stripe-js';
  import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    EmailAuthProvider,
    updateEmail,
    reauthenticateWithCredential,
    updatePassword,
    updateProfile
  } from "firebase/auth";
  import { auth } from "@/fb";

  export default {
    name: 'Dashaboard',
    components: {
      Navbar, Sidebar
    },
    watch: {

    },
    data() {
      return {
        storedUser: null,
      };
    },
    async created() {
      try {
        localStorage.removeItem('user');
        await this.checkAuthState();
        const user = auth.currentUser;
        console.log('CREA DASHBOARD:::::::::::::------------------::::::::::::::')
        if (user) {
          this.storedUser = JSON.parse(localStorage.getItem('user'));
          console.log('USUARIO GUARDADO', this.storedUser)
        }
      } catch (error) {
        console.error("Error en mounted: ", error);
        // Manejar el error, por ejemplo, redirigir al usuario, mostrar un mensaje, etc.
      }


    },
    mounted() {
      console.log(this.$route.path)
      if (this.$route.path !== '/home') {
        setTimeout(() => {
          console.log('redireccion')
          this.$router.push({ name: 'Home' });
        }, 3000);
      }

    },
    methods: {
    },
  }
</script>